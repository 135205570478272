@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-spin {
    animation: spin 30s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}